import React from "react"
import { Router } from "@reach/router"
import PodcastEpisodeDetail from "../components/Podcast-Episode/Detail"

const PodcastEpisode = () => {
  return (
    <Router>
      <PodcastEpisodeDetail path="podcast-aflevering/:slug" />
    </Router>
  )
}

export default PodcastEpisode
